import { Fragment, FunctionComponent, useState, useEffect } from "react";

import { Transition } from "@headlessui/react";
import GuestWorldAvatar from "@/components/elements/GuestWorldAvatar";
import { GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";

//#region INTERFACES
interface WorldMapButtonProps {
  totalModelCount: number;
  active: boolean;
}
//#endregion

//#region PUBLIC API
export const WorldMapButton: FunctionComponent<WorldMapButtonProps> = ({
  totalModelCount,
  active,
}) => {
  const [index, setIndex] = useState(0);
  const [isShowing, setIsShowing] = useState(true);

  const randomAvatarsCount = 3;

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((i) => (i + 1) % randomAvatarsCount);
    }, Math.random() * 8000 + 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="group hover:cursor-pointer transform scale-aiea">
        <div className="pl-1">
          <span className={`relative h-8 w-8 rounded-full ${active ? "bg-aieaorange-100" : "bg-aieaorange-900 "} flex items-center justify-center animate-pulse`} />
          <span className={`relative bottom-7 left-1 inline-block h-6 w-6 rounded-full ${active ? "bg-aieaorange-100" : "bg-aieaorange-900 "} transition duration-300 ease-out group-hover:bg-aieaorange-500`} />
        </div>

        <div
          className={`relative -mt-6 flex h-10 w-22 border-2 ${active ? "bg-denim-900 border-white" : "bg-white border-white group-hover:border-denim-900"
            } transition duration-200 ease-out rounded-3xl items-center -space-x-2`}
        >
          <Transition
            show={isShowing}
            as={Fragment}
            enter="transition ease-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`flex ml-1 mr-1 my-1 h-8 w-8 rounded-full items-center overflow-hidden`}>
              <GuestWorldAvatar
                size={"h-8 w-8"}
                index={index}
              />
            </div>
          </Transition>
          <p
            className={`
              font-semibold text-xl text-center transition duration-200 ease-out flex-grow select-none
              ${active ? "text-white" : "text-dark-background"}
            `}
          >
            {totalModelCount}
          </p>
        </div>
      </div>
    </>
  );
};
//#endregion
